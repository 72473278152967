/**
 * State container for editor related data.
 *
 * @unstable
 */

import { Injectable } from '@angular/core';

import { NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';

import { inIframe } from '@shared/utilities/browser.utilities';

export interface BrowserStateModel {
  inIframe: boolean;
}

@Injectable()
@State<BrowserStateModel>({
  name: 'browser',
  defaults: {
    inIframe: false,
  },
})
export class BrowserState implements NgxsOnInit {
  @Selector()
  static inIframe(state: BrowserStateModel): boolean {
    return state.inIframe;
  }

  ngxsOnInit({ patchState }: StateContext<BrowserStateModel>): void {
    patchState({ inIframe: inIframe() });
  }
}
